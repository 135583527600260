@use "@scss/common" as *;

.header {
  width: 100%;
  margin: base(2) 0;

  @include x-large-break {
    margin: base() 0;
  }
}

.wrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  & > *:not(:last-child) {
    margin-right: base();
  }

  @include mid-break {
    & > *:not(:last-child) {
      margin-right: base(0.5);
    }
  }
}

.logoAnchor {
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
}

.logoBox {
  position: relative;
  width: 25px;
  height: 25px;
  margin-right: base(0.5);
}

.logo {
  margin-right: base();
  // margin-left: base(0.5);
}

.logoImage {
  position: relative;
  width: 100%;
  height: 100%;
}

.logo2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  // TODO: enable when ready
  // &.show {
  //   opacity: 1;
  // }

  @include mid-break {
    opacity: 0;
  }
}

.docsButton {
  @include mid-break {
    display: none;
  }
}
