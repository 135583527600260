@use "@scss/common" as *;

.searchModal {
  width: 100vw;
  height: calc(var(--vh) * 100);
  background: var(--color-html-bg);
  color: var(--color-html);
  border: none;
  padding: 0;
  overflow: auto;
  will-change: opacity;
  transition: opacity var(--modal-trans-time) linear;
  opacity: 0;

  &:global(.modal-item--appearActive),
  &:global(.modal-item--appearDone),
  &:global(.modal-item--enterActive),
  &:global(.modal-item--enterDone) {
    opacity: 1;
  }

  &:global(.modal-item--exitActive),
  &:global(.modal-item--exitDone) {
    opacity: 0;
  }
}

.wrapper {
  padding: var(--mobile-gutter) var(--mobile-gutter) 0;
  position: relative;
  height: 100%;

  &::after {
    content: ' ';
    padding-top: base(3);
    display: block;
  }
}

.searchBar {
  margin-bottom: base(0.5);
}

.suggestions {
  @include small;
  margin: 0;
}

.suggestion {
  all: unset;
  cursor: pointer;
}

.suggestionLabel {
  text-decoration: underline;
}
