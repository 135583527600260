@use "@scss/common" as *;

.notificationsContainer {
  position: fixed;
  bottom: base();
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification {
  @include small;
  padding: base(0.5) base();
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: var(--color-gray-10);
  color: var(--color-white);
  box-shadow: var(--shadow-1);
  &:not(:last-child) {
    margin-bottom: base(0.5);
  }
}

:global(.isDark) {
  &:local() {
    .notification {
      background-color: var(--color-white);
      color: var(--color-gray-10);
      box-shadow: var(--shadow-2);
    }
  }
}
