@use "@scss/common" as *;

.hamburger {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 18px;
  height: 14px;

  @include mid-break {
    width: 16px; // use static values to avoid pixel anti-aliasing
    height: 12px;
  }
}

.line {
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: currentColor;
  will-change: transform;
  transition: transform var(--default-trans-time) linear;
  transform: translate3d(0, 0, 0);

  &:first-child {
    top: 0;
    left: 0;
    opacity: 1;
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    transform-origin: center center;
  }

  &:nth-child(3) {
    opacity: 0;
  }

  &:last-child {
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

.lineIsOpen {
  &:first-child,
  &:last-child {
    opacity: 0;
  }

  &:nth-child(2),
  &:nth-child(3) {
    opacity: 1;
  }

  &:nth-child(2) {
    transform: rotate(45deg) translate3d(0, calc(-50% + #{var(--stroke-width-bold)} / 2), 0);
  }

  &:nth-child(3) {
    transform: rotate(-45deg) translate3d(0, calc(-50% + #{var(--stroke-width-bold)} / 2), 0);
  }
}

.red {
  color: var(--color-red);
}

.green {
  color: var(--color-green);
}

.purple {
  color: var(--color-purple);
}

.blue {
  color: var(--color-blue);
}
