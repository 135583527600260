@use "@scss/common" as *;

.customCursor {
  position: fixed;
  mix-blend-mode: difference;
  border-radius: 100%;
  z-index: 100000;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: -1;
  opacity: 0.08;
  color: var(--color-gray-1);

  @include mid-break {
    display: none;
  }
}

.line {
  position: absolute;
  will-change: background-color;
  background-color: currentColor;

  &:nth-child(1) {
    width: 1px;
    height: 200vh;
    bottom: base(0.25);
    left: 0;
  }

  &:nth-child(2) {
    width: 200vh;
    height: 1px;
    left: base(0.25);
    top: 50%;
    transform: translateY(-50%);
  }

  &:nth-child(3) {
    width: 1px;
    height: 200vh;
    top: base(0.25);
    left: 0;
  }

  &:nth-child(4) {
    width: 200vh;
    height: 1px;
    right: base(0.25);
    top: 50%;
    transform: translateY(-50%);
  }
}

.isHighlighted {
  color: var(--color-gray-4);
  opacity: 0.4;
}
