@use "@scss/common" as *;

.footer {
  display: flex;
  padding: base(2) 0;
  width: 100%;
  align-items: center;

  @include mid-break {
    padding: base(.75) 0;
    padding-bottom: base(3);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mid-break {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.copyright {
  @include mid-break {
    margin-bottom: base(0.25);
  }
}

.menu {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  & > *:not(:last-child) {
    margin-right: base();
  }

  @include mid-break {
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: base(0.25);
    }
  }
}
