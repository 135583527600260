@use "@scss/common" as *;

.codeBlock {
  position: relative;

  &:hover {
    &:local() {
      .icon {
        display: flex;
      }
    }
  }

  @include mid-break {
    &:hover {
      &:local() {
        .icon {
          display: none;
        }
      }
    }
  }

  &:global() {
    code {
      @include code;
    }
  }
}

.pre {
  background: var(--color-gray-1) !important;
  padding: base(1) !important;
  margin: 0 !important;
  border: var(--color-gray-2) 1px solid !important;
  border-radius: 2px;
  @include mid-break {
    padding: base(0.5) !important;
  }
}

:global(.isDark) {
  &:local(){
    .pre {
      background: var(--color-gray-9) !important;
      border: var(--color-gray-6) 1px solid !important;
    }
  }
}

.icon {
  cursor: pointer;
  display: none;
  position: absolute;
  top: base(0.5);
  right: base(0.5);

  @include mid-break {
    display: none;
  }
}
