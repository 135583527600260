.hyperlink {
  color: currentColor;
  text-decoration: none;
  font-weight: inherit;
}

.underlineOnHover {
  &:hover {
    text-decoration: underline;
    & * {
      text-decoration: underline;
    }
  }
}

.underline {
  text-decoration: underline;

  h1, h2, h3, h4, h5, h6, p, span, code {
    text-decoration: underline;
  }
}

.colored {
  color: var(--color-blue);
}

.dimOnHover {
  &:hover {
    opacity: 0.9;
  }
}
