@use "./functions.scss" as *;
@use "./queries.scss" as *;
@use "./vars.scss" as *;
@use "./type.scss" as *;

#__next {
  height: 100%;
}

:root {
  --html-font-size: 20;
  --html-line-height: 34;

  --font-family: #{$fontFamily};

  --color-white: #ffffff;
  --color-gray-1:#f7fafc; // lightest
  --color-gray-2:#ebf0f5; // lighter
  --color-gray-3:#d8dee6; // light
  --color-gray-4: #8b949e; // gray
  --color-gray-5:#606874; // dark
  --color-gray-6:#30363d;
  --color-gray-7: #222930;
  --color-gray-8:#151a1f ; // darker
  --color-gray-9: #101316; // darkest
  --color-gray-9-rgb: 16, 19, 22;
  --color-gray-10: #0d0d0f; // almost black
  --color-gray-10-rgb: 13, 13, 15;
  --color-black: #050505;

  --color-blue: #58a6ff;
  --color-cream: #e2e8ee;

  --color-html: var(--color-gray-10);
  --color-html-bg: var(--color-white);

  --modal-trans-time: 250ms; // match value in any ModalProvider (./pages/_app.tsx)
  --default-trans-time: 150ms;
  --slider-trans-time: 750ms;
  --page-trans-time: 750ms; // match timeout value in 'PageTransition' component

  // IMPORTANT: must keep GridProvider settings in _app.js synced
  --mobile-gutter: 20px;
  --desktop-gutter: 20px;

  --modal-z-index: 2;
  --header-z-index: 3;

  --stroke-width: 1px;
  --stroke-width-bold: 2px;

  --shadow-1: 0px 2px 6px rgba(0, 0, 0, 0.075);
  --shadow-2: 0px 4px 10px rgba(0, 0, 0, 0.25);

  --col-gap: 2rem;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

html {
  background-color: var(--color-html-bg);
  color: var(--color-html);
  font-family: $fontFamily;
  font-size: calc(#{var(--html-font-size)} * 1px);
  line-height: calc(#{var(--html-line-height)} * 1px);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  font-variant-ligatures: none;

  // @include mid-break {
  //   @at-root {
  //     :root {
  //       --html-font-size: 20;
  //       --html-line-height: 30;
  //     }
  //   }
  // }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

p {
  @include p;
}

ol {
  @include ul;
}

ul {
  @include ul;

  li {
    list-style: none;

    &::before {
      position: absolute;
      content: "";
      left: base(-0.5);
      top: 16px;
      width: 4px;
      height: 4px;
      background-color: currentColor;
    }
  }
}

li {
  @include li;
  position: relative;

  &:last-child {
    margin: 0;
  }
}

small {
  @include small;
}

hr {
  border: none;
  border-top: 1px solid currentColor;
  margin: 0;
}

a {
  font-weight: 600;
  text-decoration: underline;
}

code {
  @include code;
}

input,
textarea,
select {
  margin: 0;
}

svg {
  path,
  circle,
  line {
    vector-effect: non-scaling-stroke;
  }
}
