@use "@scss/common" as *;

.button {
  all: unset;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    &:local() {
      .arrowWrapper {
        transform: translate3d(5px, 0, 0);
      }
    }
  }
}

.contents {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.arrowWrapper {
  margin-left: base(0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.type--primary,
.type--secondary {
  border-radius: 2px;

  &:local() {
    .contents {
      font-weight: normal;
      padding: base(0.25) base(0.5);
    }
  }
}

.type--primary {
  background-color: var(--color-gray-9);
  color: var(--color-cream);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray-6);

  &:hover {
    background-color: var(--color-gray-6);
    border-color: var(--color-gray-5);
  }
}

.type--secondary {
  background-color: transparent;
  border: var(--stroke-width) solid var(--color-gray-8);
  &:hover {
    border-color: var(--color-gray-3);
  }
}

.size--small {
  &:local() {
    &.type--primary,
    &.type--secondary {
      &:local() {
        .contents {
          padding: 0 base(0.5);
        }
      }
    }
  }
}

:global(.isDark) {
  &:local() {
    .type--primary {
      background-color: var(--color-cream);
      color: var(--color-gray-10);
      border-width: 1px;
      border-style: solid;
      border-color: var(--color-gray-2);

      &:hover {
        background-color: var(--color-gray-1);
        border-color: var(--color-gray-2);
      }
    }
  }
}
