@use "@scss/common" as *;

.code {
  @include code;
  background-color: var(--color-gray-2);
  padding: 4px 8px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  line-height: 1;
  word-break: break-word;
}

.size--small {
  font-size: 14px;
  padding: 4px 5px;
}

:global(.isDark) {
  &:local(){
    .code {
      background-color: var(--color-gray-8);
      color: var(--color-white);
    }
  }
}
