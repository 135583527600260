@use "@scss/common" as *;

.nav {
  @include mid-break {
    & > *:not(:last-child) {
      margin-bottom: base(0.25);
    }
  }
}

.title {
  font-weight: bold;
}

.itemLabel {
  position: relative;
  font-weight: normal;
  color: var(--color-gray-5);
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    color: var(--color-gray-10);
  }

  @include mid-break {
    white-space: auto;
    &:hover {
      color: var(--color-gray-5);
    }
  }
}

.jumplistLinkLabel {
  @include small;
  margin: 0;
  color: var(--color-gray-4);
  position: relative;

  &:hover {
    color: var(--color-gray-5);
  }

  @include mid-break {
    &:hover {
      color: var(--color-gray-4);
    }
  }
}

.itemIsActive {
  color: var(--color-gray-10);
  &:hover {
    color: var(--color-gray-10);
  }
}

.groupItems {
  padding-left: base(0.5);
  border-left: var(--color-gray-3) solid var(--stroke-width);
  @include mid-break {
    margin-top: base(0.125);
  }
}

.toggler {
  all: unset;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.chevron {
  margin-right: base(0.25);
}

.link {
  position: relative;
  display: flex;
  align-items: center;
}

.jumplist {
  display: flex;
  flex-direction: column;
}

.jumplistContent {
  padding-left: base(0.5);
  padding-top: base(0.125);
  padding-bottom: base(0.25);
}

.jumplistLink {
  @include small;
  display: block;
  &:not(:last-child) {
    margin-bottom: base(0.25);
  }
}

.linkBullet {
  width: 3px;
  height: 3px;
  background-color: currentColor;
  position: absolute;
  left: base(-0.5);
  top: 16px;
}

.jumplistBullet {
  width: 4px;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  left: base(-0.5);
  top: 10px;
}

.linkLabel {
  margin-left: base(0.5);
}

:global(.isDark) {
  &:local() {
    .itemLabel {
      color: var(--color-gray-4);

      &:hover {
        color: var(--color-white);
      }

      @include mid-break {
        &:hover {
          color: var(--color-gray-4);
        }
      }
    }

    .jumplistLinkLabel {
      color: var(--color-gray-4);

      &:hover {
        color: var(--color-white);
      }

      @include mid-break {
        &:hover {
          color: var(--color-gray-4);
        }
      }
    }

    .itemIsActive {
      color: var(--color-white);

      &:hover {
        color: var(--color-white);
      }
    }
  }
}
